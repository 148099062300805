import React, { useEffect, useRef, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button, UncontrolledAlert } from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions"

import { Link } from "react-router-dom"

const AllPendingRecipeReviews = props => {
  document.title = "PFP Admin - All Pending Recipe Reviews"

  const breadcrumbItems = [
    { title: "Pending Recipe Reviews", link: "#" },
    { title: "All Pending Recipe Reviews", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const [alertVisible, setAlertVisible] = useState(false)
  const [reasons, setReason] = useState("")
  const [error, setError] = useState("")
  const token = localStorage.getItem("token")
  const deliverModalCloseButton = useRef(null)
  const [activeModal, setActiveModal] = useState(null)

    // Trigger the modal by setting the active row's ID
    const handleModalOpen = id => {
      setActiveModal(id)
    }
  
    // Close the modal
    const handleModalClose = () => {
      setActiveModal(null)
    }

  useEffect(() => {
    props.setBreadcrumbItems("Manage Pending Recipe Reviews", breadcrumbItems)
  }, [props])

  useEffect(() => {
    const fetchAllCategories = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/recipe/reviews/list?is_approved=pending`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        setData(json.data)
        setRecords(json.data)
      } else {
        setRecords([])
      }
    }

    fetchAllCategories()
  }, [update])

  const handleApprove = async (approved, id) => {
    const data = approved === "rejected" ? { approved, reasons } : { approved }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/backend/recipe/reviews/approval/${id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            'Content-Type': "Application/json",
      
              "Authorization": `Bearer ${token}`,
            
        }
    })

    const json = await response.json()

    if(response.ok){
        setError(json.message)
            setAlertVisible(true)
          setTimeout(() => setAlertVisible(false), 10000)
        
        setUpdate(update + 1)
    }

  }

  const data = {
    columns: [
      // {
      //   label: "Image",
      //   field: "image",
      //   sort: false,
      //   width: 150,
      // },
      {
        label: "User",
        field: "user_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Recipe Name",
        field: "recipe_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Review",
        field: "review",
        sort: "asc",
        width: 150,
      },
      {
        label: "Rating",
        field: "rating",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map(row => ({
      user_name: row.user_name,
      recipe_name: row.recipe_name,
      rating: row.rating,
      review: <>{row.review} <br/>
      {
        row.files.length > 0? 
        <>
        {
          row.files.map((file)=>(
            <img height="80px" alt="recipe" className="mx-1" src={file.image_url}/>
          ))
        }
        </>
        :
        <></>
      }
      </>,

      action: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
       <button
            onClick={() => handleModalOpen(row.id)}
            className="btn btn-sm btn-warning"
          >
            Reject
          </button>
          {activeModal === row.id && (
            <div
              className="modal fade show"
              id={`${row.id}deliverModal`}
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: "block", backdropFilter: "blur(5px)" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <form
                  className="modal-content"
                  onSubmit={e => {
                    e.preventDefault() // Prevent page reload
                    handleApprove("rejected", row.id) // Call the function to handle rejection
                  }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Reason of Rejection
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleModalClose}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <textarea
                      type="text"
                      className="form-control"
                      onChange={e => setReason(e.target.value)}
                      placeholder="Enter Valid Reason"
                      rows={3}
                      required
                    ></textarea>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-danger">
                      Confirm Reject
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          <button onClick={() => handleApprove("approved", row.id)} className="btn btn-sm" style={{backgroundColor: "#24A97B", color: "white"}}>
            Approve
          </button>{" "}
          {/* <Link className="btn btn-sm btn-primary">
            Edit
          </Link>{" "}
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button> */}
        </div>
        // </div>
      ),
    })),
  }

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/recipes/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Review has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Review is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
        {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="success">
          <strong>Success!</strong> {error}
        </UncontrolledAlert>
      )}
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">
                All Pending Recipe Reviews
              </CardTitle>

              <MDBDataTable
                responsive
                bordered
                data={data}
                data-mdb-loading="true"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AllPendingRecipeReviews)
