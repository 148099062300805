import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
  Label,
  Button,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation"
import Select from "react-select"
import Dropzone from "react-dropzone"

const EditRestaurantLocation = props => {
  const { id, restId } = useParams()

  const [alertVisible, setAlertVisible] = useState(false)

  const [location_address, setAddress] = useState("")
  const [coordinates, setCoordinates] = useState("")
  const [youtube_url, setYoutube] = useState("")
  const [location_country_id, setCountryId] = useState()
  const [countries, setCountries] = useState([])
  const [location_state_id, setStateId] = useState()
  const [states, setStates] = useState([])
  const [location_city_id, setCityId] = useState()
  const [cities, setCities] = useState([])
  const [location_area_id, setAreaId] = useState()
  const [areas, setAreas] = useState([])
  const [location_phase_id, setPhaseId] = useState()
  const [phases, setPhases] = useState([])

  const [child_friendly_dining, setChildFriendly] = useState()
  const [adult_friendly_dining, setAdultFriendly] = useState()
  const [outdoor_sitting, setOutdoorSitting] = useState()
  const [indoor_sitting, setIndoorSitting] = useState()
  const [delivery, setDelivery] = useState()
  const [valet_service, setValet] = useState()
  const [play_area, setPlay] = useState()
  const [high_chair, setHighChair] = useState()
  const [wifi, setWifi] = useState()
  const [live_music, setLiveMusic] = useState()
  const [status, setActive] = useState()
  const [sort_order, setSort] = useState(0)

  const token = localStorage.getItem("token")
  const [validationErrors, setValidationErrors] = useState({})

  document.title = "PFP Admin - Edit Restaurant Location"

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "Edit Location", link: "#" },
    { title: id, link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Edit Location", breadcrumbItems)
  }, [])
  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  useEffect(() => {
    const fetchLocation = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurant/locations/${id}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        setCountryId(json.location.location_country_id)
        setStateId(json.location.location_state_id)
        setCityId(json.location.location_city_id)
        setAreaId(json.location.location_area_id)
        setPhaseId(json.location.location_phase_id)
        setCoordinates(
          json.location.location_latitude +
            ", " +
            json.location.location_longitude,
        )
        setAddress(json.location.location_address)
        setYoutube(json.location.youtube_url)
        setSort(json.location.sort_order)
        setChildFriendly(
          json.location.is_child_friendly_dining === 1 ? true : false,
        )
        setAdultFriendly(
          json.location.is_adult_friendly_dining === 1 ? true : false,
        )
        setOutdoorSitting(
          json.location.has_outdoor_sitting === 1 ? true : false,
        )
        setIndoorSitting(json.location.has_Indoor_sitting === 1 ? true : false)
        setDelivery(json.location.has_delivery === 1 ? true : false)
        setValet(json.location.has_valet_service === 1 ? true : false)
        setPlay(json.location.has_play_area === 1 ? true : false)
        setHighChair(json.location.has_high_chair === 1 ? true : false)
        setWifi(json.location.has_wifi === 1 ? true : false)
        setLiveMusic(json.location.has_live_music === 1 ? true : false)
        setActive(json.location.active === 1 ? true : false)
      }
    }

    fetchLocation()
  }, [id])

  useEffect(() => {
    const fetchAllCountry = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/locations/countries/list`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        const options = json.data.map(cat => ({
          label: cat.country_name,
          value: cat.id,
        }))
        setCountries(options || [])
      }
    }

    fetchAllCountry()
  }, [])
  useEffect(() => {
    const fetchAllStates = async () => {
      const params = new URLSearchParams({
        location_country_id: location_country_id,
      })

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/locations/states/list?${params}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        const options = json.data.map(st => ({
          label: st.state_name,
          value: st.id,
        }))
        setStates(options || [])
      }
    }
    if (location_country_id) {
      fetchAllStates()
    }
  }, [location_country_id])
  useEffect(() => {
    const fetchAllCities = async () => {
      const params = new URLSearchParams({
        location_country_id: location_country_id,
        location_state_id: location_state_id,
      })

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/locations/cities/list?${params}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        const options = json.data.map(ct => ({
          label: ct.city_name,
          value: ct.id,
        }))
        setCities(options || [])
      }
    }
    if (location_state_id) {
      fetchAllCities()
    }
  }, [location_state_id])
  useEffect(() => {
    const fetchAllAreas = async () => {
      const params = new URLSearchParams({
        location_country_id: location_country_id,
        location_state_id: location_state_id,
        location_city_id: location_city_id,
      })

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/locations/areas/list?${params}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        const options = json.data.map(ar => ({
          label: ar.area_name,
          value: ar.id,
        }))
        setAreas(options || [])
      }
    }
    if (location_city_id) {
      fetchAllAreas()
    }
  }, [location_city_id])
  useEffect(() => {
    const fetchAllPhases = async () => {
      setPhases([])
      const params = new URLSearchParams({
        location_country_id: location_city_id,
        location_state_id: location_state_id,
        location_city_id: location_city_id,
        location_area_id: location_area_id,
      })

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/locations/phases/list?${params}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )

      const json = await response.json()

      if (response.ok) {
        const options = json.data.map(ph => ({
          label: ph.phase_name,
          value: ph.id,
        }))
        setPhases(options || [])
      }
    }
    if (location_area_id) {
      fetchAllPhases()
    }
  }, [location_area_id])

  // Validation for Select Fields
  const validateForm = () => {
    let errors = {}
    if (!location_country_id) {
      errors.country = "Country is required"
    }
    if (!location_state_id) {
      errors.state = "State is required"
    }
    setValidationErrors(errors)
    return Object.keys(errors).length === 0 // Returns true if there are no errors
  }
  const handleSubmit = async e => {
    e.preventDefault()

    let active = status === true ? 1 : 0
    let is_child_friendly_dining = child_friendly_dining === true ? 1 : 0
    let is_adult_friendly_dining = adult_friendly_dining === true ? 1 : 0
    let has_outdoor_sitting = outdoor_sitting === true ? 1 : 0
    let has_Indoor_sitting = indoor_sitting === true ? 1 : 0
    let has_delivery = delivery === true ? 1 : 0
    let has_valet_service = valet_service === true ? 1 : 0
    let has_play_area = play_area === true ? 1 : 0
    let has_high_chair = high_chair === true ? 1 : 0
    let has_wifi = wifi === true ? 1 : 0
    let has_live_music = live_music === true ? 1 : 0

    const restaurant_id = restId
    // Proceed with form submission if validation passes
    const data = {
      restaurant_id,
      location_address,
      location_country_id,
      location_state_id,
      location_city_id,
      location_area_id,
      location_phase_id,
      coordinates,
      youtube_url,
      sort_order,
      active,
      is_child_friendly_dining,
      is_adult_friendly_dining,
      has_outdoor_sitting,
      has_Indoor_sitting,
      has_delivery,
      has_valet_service,
      has_play_area,
      has_high_chair,
      has_wifi,
      has_live_music,
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurant/locations/update/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
           
              "Authorization": `Bearer ${token}`,
            
          },
        },
      )
      if (response.ok) {
        navigate(`/manage-locations/${restId}`)
        setAlertVisible(true)
        setTimeout(() => setAlertVisible(false), 5000)
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  function handleStatusChange(e) {
    e.preventDefault()
    setActive(e.target.checked)
  }
  return (
    <React.Fragment>
      {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="success">
          <strong>Well done!</strong> You successfully added a new restaurant.
        </UncontrolledAlert>
      )}
      {/* form */}
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Edit Location</h4>

              <AvForm onSubmit={handleSubmit} className="needs-validation mt-4">
                <Row>
                  <Col md="4">
                    <AvGroup className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Country</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setCountryId(e.value)}
                        options={countries}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        value={countries.find(
                          option => option.value === location_country_id,
                        )}
                      />
                      {validationErrors.country && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.country}
                        </div>
                      )}
                    </AvGroup>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose States</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setStateId(e.value)}
                        options={states}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        value={states.find(
                          option => option.value === location_state_id,
                        )}
                      />
                      {validationErrors.state && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.state}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose City</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setCityId(e.value)}
                        options={cities}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        value={cities.find(
                          option => option.value === location_city_id,
                        )}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Area</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setAreaId(e.value)}
                        options={areas}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        value={areas.find(
                          option => option.value === location_area_id,
                        )}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Phase</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setPhaseId(e.value)}
                        options={phases}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        value={phases.find(
                          option => option.value === location_phase_id,
                        )}
                      />
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Location Address
                      </Label>
                      <AvField
                        name="location_address"
                        placeholder="Location Address"
                        onChange={e => setAddress(e.target.value)}
                        type="text"
                        errorMessage="Enter Location Address"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={location_address}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Add Coordinates
                      </Label>
                      <AvField
                        name="coordinates"
                        placeholder="23.12345678, 76.87654321"
                        onChange={e => setCoordinates(e.target.value)}
                        type="text"
                        errorMessage="Enter Valid Coordinates"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={coordinates}
                      />
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">
                        Youtube Video URL
                      </Label>
                      <AvField
                        name="youtube url"
                        placeholder="Enter Youtube URL"
                        type="text"
                        // errorMessage="Please provide a valid youtube link."
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom04"
                        onChange={e => setYoutube(e.target.value)}
                        value={youtube_url}
                      />
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">Sort Order</Label>
                      <AvField
                        name="sort number"
                        placeholder="Enter Sort Number"
                        type="number"
                        // errorMessage="Please provide a valid number."
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom04"
                        onChange={e => setSort(e.target.value)}
                        value={sort_order}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={child_friendly_dining}
                    // defaultChecked={child_friendly_dining}
                    checked={child_friendly_dining}
                    onClick={e => setChildFriendly(!child_friendly_dining)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Child Friendly Dinning
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={adult_friendly_dining}
                    // defaultChecked={adult_friendly_dining}
                    checked={adult_friendly_dining}
                    onClick={e => setAdultFriendly(!adult_friendly_dining)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Adult Friendly Dinning
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={outdoor_sitting}
                    // defaultChecked={outdoor_sitting}
                    checked={outdoor_sitting}
                    onClick={e => setOutdoorSitting(!outdoor_sitting)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Outdoor Sitting
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={indoor_sitting}
                    // defaultChecked={indoor_sitting}
                    checked={indoor_sitting}
                    onClick={e => setIndoorSitting(!indoor_sitting)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Indoor Sitting
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={delivery}
                    // defaultChecked={delivery}
                    checked={delivery}
                    onClick={e => setDelivery(!delivery)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Delivery
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={valet_service}
                    // defaultChecked={valet_service}
                    checked={valet_service}
                    onClick={e => setValet(!valet_service)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Valet Service
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={play_area}
                    // defaultChecked={play_area}
                    checked={play_area}
                    onClick={e => setPlay(!play_area)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Play Area
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={high_chair}
                    // defaultChecked={high_chair}
                    checked={high_chair}
                    onClick={e => setHighChair(!high_chair)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    High Chair
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value={wifi}
                    // defaultChecked={wifi}
                    checked={wifi}
                    id="invalidCheck"
                    onClick={e => setWifi(!wifi)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Wifi Available
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={live_music}
                    // defaultChecked={live_music}
                    checked={live_music}
                    onClick={e => setLiveMusic(!live_music)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Live Music
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    // value={status}
                    // defaultChecked={status}
                    checked={status}
                    onClick={e => setActive(!status)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Active
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>

                <Button color="primary" type="submit">
                  Update Location
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(EditRestaurantLocation)
