import React, { useEffect, useMemo, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import "./datatables.scss"
import { Link, useParams } from "react-router-dom"

const AllLocations = props => {
  document.title = "PFP Admin - All Locations"

  const {id} = useParams()

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "Manage Locations", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const token = localStorage.getItem("token")

  useEffect(() => {
    props.setBreadcrumbItems("Manage Locations", breadcrumbItems)
  }, [props])

  useEffect(() => {
    const fetchLocations = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurant/locations/list/${id}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        setData(json.locations)
        setRecords(json.locations)
      }else{
        setRecords([])
      }
    }

    fetchLocations()
  }, [id, update])

  const data = useMemo(()=>(
    {
      columns: [
        {
          label: "Name",
          field: "restaurant_name",
          sort: false,
          width: 150,
        },
        {
          label: "Address",
          field: "location_address",
          sort: false,
          width: 150,
        },
   
        {
          label: "Action",
          field: "action",
          sort: false,
          width: 100,
        },
      ],
      rows: records.map(row => ({
        restaurant_name: row.restaurant_name,
        location_address: row.location_address,
     
  
        action: (
          // <div className="d-flex justify-content-center gap-2">
          <div className="d-flex justify-content-end gap-2">
            <Link
              to={`/edit-location/${row.id}/${id}`}
              className="btn btn-sm btn-primary"
            >
              Edit
            </Link>{" "}
            <button
              onClick={() => handleDelete(row.id)}
              className="btn btn-sm btn-danger"
            >
              Delete
            </button>
          </div>
          // </div>
        ),
      })),
    }
  ), [records, id]) 

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/restaurant/locations/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Location has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Location is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">All Restaurant Locations <Link to={`/add-restaurant-location/${id}`}><Button type="button" color="primary" className="waves-effect waves-light">Add New Location</Button></Link></CardTitle>
             

              <MDBDataTable
                responsive
                bordered
                data={data}
                data-mdb-loading="true"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AllLocations)
