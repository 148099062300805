import React, { useEffect, useMemo, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import "./datatables.scss"
import { Link, useParams } from "react-router-dom"

const AllMenues = props => {
  document.title = "PFP Admin - Manage Menu"

  const {id} = useParams()

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "Manage Menu", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const token = localStorage.getItem("token") 

  useEffect(() => {
    props.setBreadcrumbItems("Manage Menu", breadcrumbItems)
  }, [props])

  useEffect(() => {
    const fetchLocations = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurants/menu/list/${id}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        setData(json.data)
        setRecords(json.data)
      }else{
        setRecords([])
      }
    }

    fetchLocations()
  }, [id, update])

  const data = useMemo(()=>({
    columns: [
      {
        label: "Restaurant",
        field: "restaurant_name",
        sort: false,
        width: 150,
      },
      {
        label: "Dish Name",
        field: "dish_name",
        sort: false,
        width: 150,
      },
      {
        label: "Dish Category",
        field: "dish_category_name",
        sort: false,
        width: 150,
      },
      {
        label: "Dish Cuisine",
        field: "dish_cuisine_name",
        sort: false,
        width: 150,
      },
      {
        label: "Price",
        field: "price",
        sort: false,
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: false,
        width: 150,
      },
 
      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map(row => ({
      restaurant_name: row.restaurant_name,
      dish_name: row.dish_name,
      dish_category_name: row.restaurant_dish_category_name,
      dish_cuisine_name: row.restaurant_dish_cuisine_name,
      price: row.price,
      status: row.active === 1? "Active" : "Inactive",
   

      action: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
          <Link
            to={`/edit-menu/${row.id}/${id}`}
            className="btn btn-sm btn-primary"
          >
            Edit
          </Link>{" "}
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>
        </div>
        // </div>
      ),
    })),
  }
), [records, id]) 
  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/restaurants/menu/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Menu has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Menu is safe :)",
            icon: "error",
          })
        }
      })
  }
  const handleDeleteAll = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/restaurants/menu/delete/byRestaurant/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              },
            )
            if(response.ok){
              setUpdate(update + 1)
            }
           
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Menus have been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Menu is safe :)",
            icon: "error",
          })
        }
      })
  }



  return (
    <React.Fragment>
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <CardTitle className="h4 d-flex justify-content-between">
              All Menus
              <div>
                <Button
                  type="button"
                  color="danger"
                  className="waves-effect waves-light me-2"
                  onClick={()=>handleDeleteAll(id)}
                >
                  Delete All
                </Button>
                <Link to={`/add-menu/${id}`}>
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                  >
                    Add New Menu
                  </Button>
                </Link>
              </div>
            </CardTitle>
  
            <MDBDataTable
              responsive
              bordered
              data={data}
              data-mdb-loading="true"
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </React.Fragment>
  
  )
}

export default connect(null, { setBreadcrumbItems })(AllMenues)
