import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
  Label,
  Button,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation"
import Select from "react-select"
import Dropzone from "react-dropzone"

const EditNewMenu = props => {
  const { id, restId } = useParams()

  const [alertVisible, setAlertVisible] = useState(false)

  const [dish_name, setDishName] = useState("")
  const [dish_description, setDishDescription] = useState("")
  const [serving_size, setServingSize] = useState("")
  const [price, setPrice] = useState("")

  const [restaurant_dish_category_id, setDishCategoryId] = useState()

  const [dishCategories, setDishCategories] = useState([])

  const [restaurant_dish_cuisine_id, setDishCuisineId] = useState()
  const [dishCuisine, setDishCuisines] = useState([])

  const [status, setActive] = useState()
  const [sort_order, setSort] = useState(0)
  const token = localStorage.getItem("token")

  const [validationErrors, setValidationErrors] = useState({})

  document.title = "PFP Admin - Edit Restaurant Menu"

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "Edit Menu", link: "#" },
    { title: id, link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Edit Menu", breadcrumbItems)
    console.log("aqdas")
  }, [])
  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  useEffect(() => {
    const fetchMenu = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurants/menu/${id}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        setDishName(json.data.dish_name)
        setDishDescription(json.data.dish_description)
        setPrice(json.data.price)
        setServingSize(json.data.serving_size)
        setDishCategoryId(json.data.restaurant_dish_category_id)
        setDishCuisineId(json.data.restaurant_dish_cuisine_id)

        setSort(json.data.sort_order)

        setActive(json.data.active === 1 ? true : false)
      }
    }

    fetchMenu()
  }, [id])
  useEffect(() => {
    const fetchAllDishCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/restaurants/dishes/category/list`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()

        if (response.ok) {
          const options = json.data.map(cat => ({
            label: cat.category_name,
            value: cat.id,
          }))
          setDishCategories(options || [])
        }
      } catch (error) {}
    }
    const fetchAllDishCuisines = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/restaurant/dish/cusines/list`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()

        if (response.ok) {
          const options = json.data.map(cui => ({
            label: cui.cuisine_name,
            value: cui.id,
          }))
          setDishCuisines(options || [])
        }
      } catch (error) {}
    }

    fetchAllDishCategories()
    fetchAllDishCuisines()
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    let active = status === true ? 1 : 0

    const restaurant_id = restId

    const formData = new FormData()


    console.log("rest id is: "+restaurant_id)


    formData.append("restaurant_id", restaurant_id)
    formData.append("restaurant_dish_category_id", restaurant_dish_category_id)
    formData.append("restaurant_dish_cuisine_id", restaurant_dish_cuisine_id)
    formData.append("dish_name", dish_name)
    formData.append("dish_description", dish_description)
    formData.append("serving_size", serving_size)
    formData.append("price", price)
    formData.append("sort_order", sort_order)
    formData.append("active", active)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurants/menu/update/${id}`,
        {
          method: "POST",
          body: formData,
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        },
      )
      if (response.ok) {
        navigate(`/manage-menu/${restId}`)
        setAlertVisible(true)
        setTimeout(() => setAlertVisible(false), 5000)
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <React.Fragment>
      {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="success">
          <strong>Well done!</strong> You successfully added a new restaurant.
        </UncontrolledAlert>
      )}
      {/* form */}
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Edit Menu</h4>

              <AvForm onSubmit={handleSubmit} className="needs-validation mt-4">
                <Row>
                  <Col md="4">
                    <AvGroup className="mb-3">
                      <Label htmlFor="validationCustom02">
                        Choose Dish Category
                      </Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setDishCategoryId(e.value)}
                        options={dishCategories}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        value={dishCategories.find((option) => option.value === restaurant_dish_category_id)}
                      />
                      {validationErrors.country && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.country}
                        </div>
                      )}
                    </AvGroup>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Cuisine</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setDishCuisineId(e.value)}
                        options={dishCuisine}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        value={dishCuisine.find((option) => option.value === restaurant_dish_cuisine_id)}
                      />
                      {validationErrors.state && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.state}
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Dish Name</Label>
                      <AvField
                        name="dish_name"
                        placeholder="Enter Dish Name"
                        value={dish_name}
                        onChange={e => setDishName(e.target.value)}
                        type="text"
                        errorMessage="Enter Valid Dish Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Dish Description
                      </Label>
                      <AvField
                        name="dish_description"
                        placeholder="Dish Description"
                        value={dish_description}
                        onChange={e => setDishDescription(e.target.value)}
                        type="textarea"
                        rows={5}
                        // errorMessage="Enter Valid Dish Description"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Serving Size</Label>
                      <AvField
                        name="serving_size"
                        placeholder="Serving Size"
                        value={serving_size}
                        onChange={e => setServingSize(e.target.value)}
                        type="number"
                        // errorMessage="Enter Valid Serving Size"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Price</Label>
                      <AvField
                        name="price"
                        placeholder="Enter Price"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                        type="number"
                        errorMessage="Enter Valid Price"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">Sort Order</Label>
                      <AvField
                        name="sort number"
                        placeholder="Enter Sort Number"
                        value={sort_order}
                        type="number"
                        // errorMessage="Please provide a valid number."
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom04"
                        onChange={e => setSort(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                 
                    id="invalidCheck"
                    // value={status}
                    // defaultChecked={status}
                    checked={status}
                    onClick={e => setActive(!status)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Active
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>

                <Button color="primary" type="submit">
                  Update Menu
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(EditNewMenu)
